<template>
  <CCard ref="infoBox">
    <CCardHeader>
      <CRow align-horizontal="between">
        <CCol col="6">
          <CIcon name="cil-grid" />لیست تمام زمین های ثبت شده
        </CCol>
      </CRow>
    </CCardHeader>
    <CCardBody>
      <div class="farmListBoxViewer">
        <CCallout style="border-right-color: #8679ff; margin-right: 4px">
          <small class="text-muted">مجموع مساحت تمام زمین های منطقه شما:</small>
          <input
            type="text"
            v-model="farmerPhone"
            placeholder="جستجو بر اساس تلفن"
            class="searchInput"
          />
          <input
            type="text"
            v-model="farmName"
            placeholder="جستجو بر اساس نام زمین"
            class="searchInput"
          />
          <button
            class="serachBtn"
            @click="getFarmsList()"
          >
            <i class="fa fa-search" aria-hidden="true"></i>
          </button>
          <br />
          <strong class="h4 text-danger">
            {{ sumAreas.toFixed(2) }} هکتار
          </strong>
        </CCallout>
        <div :style="farmListItemBox" class="farmListItemBox">
          <vue-element-loading
            :active="loading"
            :text="loadingText"
            spinner="bar-fade-scale"
            color="var(--secondary)"
          />
          <CRow>
            <CCol md="3" v-for="(item, index) in farmsList" :key="index">
              <farmItem
                :itemModel="item"
                :selectedItemId="selectedFarmItem"
                @detailFarmItem="getFarmInfo"
              />
            </CCol>
          </CRow>
        </div>
      </div>
      <div class="farmItemBoxViewer" v-if="selectedFarmItem > 0">
        <detail v-if="selectedFarmItem > 0" :farmId="selectedFarmItem" />
      </div>
    </CCardBody>
  </CCard>
</template>

  <script >
import { mapActions } from "vuex";
import polyGonStructure from "../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import detail from "./farmDetail.vue";
import farmItem from "./farmItem.vue";

export default {
  name: "FarmsList",
  components: {
    detail,
    farmItem,
  },
  data() {
    return {
      farmsList: [],
      details: [],
      sumAreas: 0,
      loading: false,
      farmerPhone: "",
      farmName: "",
      loadingText: "در حال دریافت اطلاعات ...",
      farmListItemBox: `height: ${window.innerHeight - 277}px;min-height: ${
        window.innerHeight - 277
      }px;max-height: ${
        window.innerHeight - 277
      }px;overflow-y: auto;overflow-x: hidden;`,
      selectedFarmItem: 0,
    };
  },
  methods: {
    ...mapActions("connectionAgent", ["Gets"]),

    async getFarmsList() {
      this.farmsList = [];
      this.sumAreas = 0;
      this.loading = true;
      this.loadingText = "در حال دریافت اطلاعات...";
      let result = await this.Gets({
        farmName: this.farmName,
        farmerPhone: this.farmerPhone,
      });
      if (result.data) {
        if (result.data.length > 0)
          this.selectedFarmItem = result.data[0].farmId;
        this.farmsList = result.data.map((item, id) => {
          let polygon = item.area.substring(
            item.area.indexOf("("),
            item.area.indexOf(")")
          );
          polygon = polygon.replaceAll("(", "");
          polygon = polygon.replaceAll(")", "");
          polygon = polygon.split(", ");
          polygon = polygon.map((item) => item.split(" "));
          const newArray = [polygon];
          polyGonStructure.features[0].geometry.coordinates = newArray;
          let farmArea = turf.area(polyGonStructure.features[0].geometry);
          item.farmArea = Intl.NumberFormat("fa").format(
            (farmArea / 10000).toFixed(2)
          );
          this.sumAreas += farmArea / 10000;
          return { ...item, id };
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `هیچ زمینی در محدوده شما ثبت نشده است <br /><br />`,
          type: "info",
        });
      }
      this.loading = false;
    },
    async getFarmInfo(farmId) {
      this.selectedFarmItem = farmId;
    },
  },
  watch: {
    farmerPhone() {
      if (this.farmerPhone === "") this.getFarmsList();
    },
    farmName() {
      if (this.farmName === "") this.getFarmsList();
    },
  },
  mounted() {
    this.getFarmsList();
  },
};
</script>

  <style scoped>
.headerBtn {
  float: left;
  margin-right: 4px;
  margin-bottom: 2px;
  width: 10rem;
  direction: rtl;
}

.farmListBoxViewer {
  float: right;
  clear: right;
  width: 65%;
  box-shadow: 0px 0px 4px 2px #bbb;
}
.farmListItemBox {
  overflow-y: auto;
}
.farmListItemBox::-webkit-scrollbar {
  width: 8px;
}
.farmListItemBox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.farmListItemBox::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.farmListItemBox::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}
.farmItemBoxViewer {
  float: right;
  margin-right: 2%;
  width: 33%;
  box-shadow: 0px 0px 5px 1px #bbb;
  padding: 5px;
}
.searchInput {
  width: 14vw;
  border: none;
  border-bottom: 1px solid #858585;
  margin: 0px 10px;
  outline: none;
}
.serachBtn {
  border: none;
  background-color: #f80;
  width: 4vw;
  height: 3vh;
  border-radius: 102pc;
  color: #fff;
}
</style>
