<template>
  <div>
    <CCallout style="width: 100%; border-right-color: #6a8500">
      <small class="text-muted">نمایش جزئیات زمین:</small>
      <br />
      <strong class="h4">جزئیات زمین {{ title }}</strong>
    </CCallout>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div id="mapBox">
      <div id="map"></div>
    </div>
    <div class="farmInfoBox" :style="farmInfoBoxSize">
      <div class="farmInfoBoxItem">
        <h3>نام</h3>
        <h4>{{ title }}</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>محصول (نوع محصول)</h3>
        <h4>{{ productName }} ({{ productType }})</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>نوع کشت (تاریخ کشت)</h3>
        <h4>{{ cultivationsTypeTitle }} ({{ cultivationsDate }})</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>استان و شهرستان</h3>
        <h4>{{ stateName }} ({{ cityName }})</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>بخش و روستا</h3>
        <h4>{{ district }} ({{ village }})</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>هکتار</h3>
        <h4>{{ farmArea }}</h4>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapActions, mapState } from "vuex";
import polyGonStructure from "../../store/data/polygonFeature.json";
import * as turf from "@turf/turf";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { ZoomControl } from "mapbox-gl-controls";

export default {
  name: "detail",
  props: ["farmId"],
  data() {
    return {
      title: null,
      productType: null,
      productName: null,
      cultivationsTypeTitle: null,
      cultivationsDate: null,
      stateName: null,
      cityName: null,
      district: null,
      village: null,
      farmArea: 0,
      loading: false,
      loadingText: "در حال دریافت اطلاعات زمین ... ",
      farmInfoBoxSize: `height: ${window.innerHeight - 540}px;min-height: ${
        window.innerHeight - 540
      }px;max-height: ${
        window.innerHeight - 540
      }px;overflow-y: auto;overflow-x: hidden;`,
      map: null,
      polygonCenter: null,
      formatedPolygon: null,
    };
  },
  watch: {
    farmId: function () {
      this.fetchFarm();
    },
  },
  components: {},
  methods: {
    ...mapActions("farm", ["Get"]),
    async fetchFarm() {
      this.loading = true;
      var result = await this.Get({ id: this.farmId });
      this.title = result.title;
      this.productType = result.productType;
      this.productName = result.productName;
      this.cultivationsTypeTitle = result.cultivationsTypeTitle;
      this.cultivationsDate = result.cultivationsDate;
      this.stateName = result.stateName;
      this.cityName = result.cityName;
      this.district = result.district;
      this.village = result.village;
      let polygon = result.area.substring(
        result.area.indexOf("("),
        result.area.indexOf(")")
      );
      polygon = polygon.replaceAll("(", "");
      polygon = polygon.replaceAll(")", "");
      polygon = polygon.split(", ");
      polygon = polygon.map((item) => item.split(" "));
      const newArray = [polygon];
      polyGonStructure.features[0].geometry.coordinates = newArray;
      let farmArea = turf.area(polyGonStructure.features[0].geometry);
      this.farmArea = Intl.NumberFormat("fa").format(
        (farmArea / 10000).toFixed(2)
      );
      this.loading = false;
    },
    addPolygonLayer(polygon) {
      let geojason = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [polygon],
        },
      };
      if (this.map.getSource("maine")) {
        if (this.map.getLayer("maine")) {
          this.map.removeLayer("maine");
        }
        this.map.removeSource("maine");
      }
      if (this.map.getSource("indexImage")) {
        if (this.map.getLayer("indexImage-layer")) {
          this.map.removeLayer("indexImage-layer");
        }
        this.map.removeSource("indexImage");
      }
      this.map.addSource("maine", {
        type: "geojson",
        data: geojason,
      });
      this.map.addLayer({
        id: "maine",
        type: "fill",
        source: "maine",
        layout: {},
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.5,
        },
      });
    },
  },
  mounted() {
    mapboxgl.accessToken =
      "pk.eyJ1IjoiYmlvY2hpYXBwIiwiYSI6ImNsMzhiOHdyaTA1OXYzZG13aGI5bXc0Y2YifQ.KK2ncJJG5hJWECxJ9jQwCA";
    if (mapboxgl.getRTLTextPluginStatus() !== "loaded") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/biochiapp/cl4chu50q000314qsi4ng5wj1",
      center: [59.55679711007316, 36.316138357670575],
      zoom: 11,
      showZoom: true,
      attributionControl: false,
    });
    this.map.addControl(new ZoomControl(), "bottom-left");
    this.map.on("load", () => {});
    this.map.on("draw.create", this.addPolygonLayer);
    this.fetchFarm();
  },
};
</script>
  <style scoped>
div.form-control {
  margin-left: 1rem;
}

.farmInfoBox {
  width: 100%;
  float: right;
  clear: both;
}
.farmInfoBoxItem {
  width: 100%;
  float: right;
  clear: both;
  background-color: #eeecff;
  margin-top: 4px;
  height: 55px;
  border: 1px solid #7972b5;
}
.farmInfoBoxItem h3 {
  width: 98%;
  float: right;
  clear: both;
  font-size: 12px;
  margin: 4px;
  text-align: right;
  padding-right: 7px;
  padding-top: 2px;
  border-bottom: 1px ridge #4638c2;
  padding-bottom: 4px;
  background-color: #6e5df9;
  color: #fff;
}
.farmInfoBoxItem h4 {
  width: 100%;
  float: right;
  clear: both;
  font-size: 14px;
  color: #3f4b5c;
  margin-top: 2px;
  padding-right: 7px;
  font-family: "Dirooz";
}
#mapBox {
  width: 100%;
  min-height: 250px;
  background-color: #bbb;
  background-image: url("../../assets/img/farmImageMapBox.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
