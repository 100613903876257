<template>
  <CRow>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <h4 v-if="dietPlanBtnList.length == 0" class="emptyTitle">
      هیچ برنامه غذایی برای زمین یافت نشد.
    </h4>
    <CRow v-else>
      <CCol md="2">
        <div
          :class="
            showImageId == item.id
              ? 'activeDietPlan dietPlanBtn'
              : 'dietPlanBtn'
          "
          v-for="(item, index) of dietPlanBtnList"
          :key="index"
          @click="showDietPlanImage(item.id)"
        >
          <img :src="getSrc(item.banner, 'Banner')" alt="" />
          <h2>{{ item.title }}</h2>
        </div>
      </CCol>
      <CCol md="10" class="planInfoBox">
        <img
          v-for="(item, index) of dietPlanList"
          :key="index"
          :src="getSrc(item.image, 'Image')"
          v-show="showImageId == item.id"
        />
      </CCol>
    </CRow>
  </CRow>
</template>

  <script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";

export default {
  name: "dietPlan",
  props: ["farmId"],
  data() {
    return {
      loading: false,
      loadingText: "",
      dietPlanBtnList: [],
      dietPlanList: [],
      showImageId: 0,
    };
  },
  components: {},
  methods: {
    ...mapActions("connectionAgent", ["GetFarmDietPlanByFarmId"]),
    getSrc(image, type) {
      if (image) return `${apiUrlRoot}/DietPlan/${type}/${image}`;
    },
    async fetch() {
      this.loading = true;
      this.loadingText = "دریافت برنامه غذایی زمین";
      var result = await this.GetFarmDietPlanByFarmId({
        farmId: this.farmId,
      });
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.dietPlanBtnList.push({
              id: value.id,
              title: value.title,
              banner: value.banner,
            });
            this.dietPlanList.push({
              id: value.id,
              description: value.description,
              image: value.image,
            });
          });
          this.showImageId = result.data[0].id;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    showDietPlanImage(id) {
      this.showImageId = id;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
  <style scoped>
.dietPlanBtn {
  width: 100%;
  float: right;
  margin: 28px 5px 5px 5px;
  box-shadow: 0px 0px 3px 0px #000;
  border-radius: 25px;
  height: 30px;
  cursor: pointer;
}
.dietPlanBtn img {
  float: right;
  width: 55px;
  height: 55px;
  margin: -4% -2px 0px 0px;
  border-radius: 100px;
  border: 3px solid #c7c7c7;
}
.dietPlanBtn h2 {
  float: right;
  font-size: 1rem;
  text-align: start;
  padding-right: 6px;
  padding-top: 3px;
}
.dietPlanBtn:hover {
  box-shadow: 0px 0px 3px 2px greenyellow;
}
.activeDietPlan {
  background-color: greenyellow;
}

.planInfoBox {
  float: right;
  width: 100%;
}
.planInfoBox img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.planInfoBox p {
  float: right;
  border: 1px double #f9b115;
  padding: 2px;
  width: 100%;
  background-color: bisque;
}

.emptyTitle {
  width: 100;
  padding-top: 10%;
  color: #bbb;
  text-align: center;
}
</style>
