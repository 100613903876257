<template>
  <CCard
    style="background-color: #f0f3f5c2"
    :class="selectedItemId == id ? 'cardSelected' : ''"
  >
    <CRow class="bodyItem">
      <h4 class="itemFarmArea">{{ farmArea }} هکتار</h4>
      <div class="bodyItemValue">
        <h6>نام :</h6>
        <h5>{{ title }}</h5>
      </div>
      <div class="bodyItemValue">
        <h6>نوع محصول :</h6>
        <h5>{{ productType }}</h5>
      </div>
      <div class="bodyItemValue">
        <h6>نوع کشت :</h6>
        <h5>{{ cultivationsType }}</h5>
      </div>
      <div class="bodyItemValue">
        <h6>مکان زمین :</h6>
        <h5>{{ address }}</h5>
      </div>
    </CRow>
    <CRow
      style="margin-top: 15px"
      :class="selectedItemId == id ? 'btnBox' : ''"
    >
      <button
        class="farmItemBtn btn-View"
        v-c-tooltip="{
          content: 'جزئیات',
          placement: 'top',
        }"
        @click="detailItem()"
      >
        <i class="fa fa-eye" aria-hidden="true"></i>
      </button>
      <button
        class="farmItemBtn btn-DietPlan"
        v-c-tooltip="{
          content: 'برنامه غذایی',
          placement: 'top',
        }"
        @click="loadSubFormFarm('farmDietPlan', 'برنامه غذایی')"
      >
        <i class="fa fa-cutlery" aria-hidden="true"></i>
      </button>
      <button
        class="farmItemBtn btn-Advice"
        v-c-tooltip="{
          content: 'توصیه ها',
          placement: 'top',
        }"
        @click="loadSubFormFarm('farmAdvice', 'توصیه های زمین...')"
      >
        <i class="fa fa-graduation-cap" aria-hidden="true"></i>
      </button>
    </CRow>

    <VueModal
      v-model="modelShowState"
      :title="modalTitle"
      wrapper-class="animate__animated animate__faster"
      in-class="animate__fadeIn"
      out-class="animate__fadeOut"
      bg-class="animate__animated"
      :bg-in-class="`animate__fadeInDown`"
      :bg-out-class="`animate__fadeOutDown`"
      style="max-width: 800px !important"
    >
      <component v-if="subForm != null" v-bind:is="subForm" :farmId="id" />
    </VueModal>
  </CCard>
</template>

<script>
import farmAdvice from "./farmAdvice.vue";
import farmDietPlan from "./farmDietPlan.vue";

export default {
  name: "farmItem",
  props: {
    itemModel: {
      type: Object,
    },
    selectedItemId: {
      type: Number,
    },
  },
  components: {
    farmAdvice,
    farmDietPlan,
  },
  data() {
    return {
      modelShowState: false,
      modalTitle: "",
      subForm: null,

      id: 0,
      title: "",
      productType: "",
      cultivationsType: "",
      address: "",
      canEdit: true,
      canDelete: true,
      farmArea: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    fetchData() {
      this.id = this.itemModel.farmId;
      this.title = this.itemModel.title;
      this.productType = this.itemModel.product;
      this.cultivationsType = this.itemModel.cultivationsType;
      this.address = this.itemModel.address;
      this.canEdit = this.itemModel.canEdit;
      this.canDelete = this.itemModel.canDelete;
      this.farmArea = this.itemModel.farmArea;
    },
    detailItem() {
      this.$emit("detailFarmItem", this.id);
    },
    loadSubFormFarm(subFormName, subFormTitle) {
      this.subForm = subFormName;
      this.modalTitle = subFormTitle;
      this.modelShowState = true;
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style scoped>
.headerItem {
  width: 100%;
  height: 10rem;
  padding: 5px;
  float: right;
}
.headerItem h3 {
  clear: both;
  float: right;
  width: 100%;
  font-size: 13px;
  text-align: center;
  height: 10px;
}
.headerItem .mapView {
  clear: both;
  float: right;
  width: 100%;
  height: 8rem;
  background-color: #bbb;
}
.headerItem label {
  float: left;
  clear: both;
  width: 100%;
  padding: 0 5px;
  background-color: #f80;
  color: #fff;
  font-size: 13px;
  text-align: center;
}

.cardSelected {
  background-color: #8679ff !important;
  box-shadow: 0px 8px 8px 3px #8679ff !important;
}
.bodyItem {
  float: right;
  clear: both;
  padding: 6px;
  min-height: 230px;
}
.bodyItemValue {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.bodyItemValue h6 {
  font-size: 12px; 
}
.bodyItemValue h5 {
  font-size: 13px;
}

.farmItemBtn {
  width: 25px;
  height: 25px;
  float: right;
  margin-right: 5px;
  border-radius: 100%;
  text-align: center;
  font-size: 13px;
  padding: 4px 1px 0px 0px;
  border: none;
  color: rgb(255 255 255);
  bottom: -3%;
  position: absolute;
}

.itemFarmArea {
  font-size: 1rem;
  text-align: center;
  width: 100%;
}

.btn-Edit {
  background-color: rgb(52, 116, 253);
  right: 1%;
}
.btn-Delete {
  background-color: red;
  right: 15%;
}
.btn-View {
  background-color: rgb(60 71 95);
  left: 30%;
}
.btn-DietPlan {
  background-color: rgb(60 71 95);
  left: 15%;
}
.btn-Advice {
  background-color: rgb(60 71 95);
  left: 0%;
}
.btn-Colleagues {
  background-color: rgb(60 71 95);
  left: 2%;
}

.btnBox button {
  background-color: #fff;
  border: 1px solid #f80;
}
.btnBox i {
  color: #f80;
}
</style>
