<template>
  <CRow>
    <vue-element-loading
      :active="loading"
      :text="loadingText"
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <CCol md="3">
      <div
        :class="
          showAdviceId == item.id ? 'activeAdvice adviceBtn' : 'adviceBtn'
        "
        v-for="(item, index) of adviceBtnList"
        :key="index"
        @click="showAviceAdvice(item.id)"
      >
        <img :src="getSrc(item.image)" alt="" />
        <h2>{{ item.name }}</h2>
      </div>
    </CCol>
    <CCol md="9" class="planInfoBox">
      <div
        v-for="(item, index) of adviceList"
        :key="index"
        v-show="showAdviceId == item.id"
        v-html="item.adviceInfo"
        class="adviceInfoBox"
        :style="adviceInfoHeight"
      ></div>
    </CCol>
  </CRow>
</template>

  <script>
import { mapActions } from "vuex";
import { apiUrlRoot } from "../../constants/config";

export default {
  name: "adviceInfo",
  props: ["farmId"],
  data() {
    return {
      loading: false,
      loadingText: "",
      adviceBtnList: [],
      adviceList: [],
      showAdviceId: 0,
      adviceInfoHeight: `height: ${window.innerHeight - 170}px;min-height: ${
        window.innerHeight - 170
      }px;max-height: ${window.innerHeight - 170}`,
    };
  },
  components: {},
  methods: {
    ...mapActions("connectionAgent", ["GetFarmAdviceByFarmId"]),
    getSrc(image) {
      if (image) return `${apiUrlRoot}/Advice/${image}`;
    },
    async fetch() {
      this.loading = true;
      this.loadingText = "دریافت  توصیه های زمین";
      var result = await this.GetFarmAdviceByFarmId({
        farmId: this.farmId,
      });
      if (result.succeeded) {
        if (result.data.length > 0) {
          result.data.forEach((value, index) => {
            this.adviceBtnList.push({
              id: value.id,
              name: value.name,
              image: value.image,
            });
            this.adviceList.push({
              id: value.id,
              description: value.description,
              adviceInfo: value.adviceInfo,
            });
          });
          this.showAdviceId = result.data[0].id;
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `${result.message} <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
    showAviceAdvice(id) {
      this.showAdviceId = id;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
  <style scoped>
.adviceBtn {
  width: 100%;
  float: right;
  margin: 28px 5px 5px 5px;
  box-shadow: 0px 0px 3px 0px #000;
  border-radius: 25px;
  height: 30px;
  cursor: pointer;
}
.adviceBtn img {
  float: right;
  width: 55px;
  height: 55px;
  margin: -4% -2px 0px 0px;
  border-radius: 100px;
  border: 3px solid #c7c7c7;
}
.adviceBtn h2 {
  float: right;
  font-size: 1rem;
  text-align: start;
  padding-right: 6px;
  padding-top: 3px;
}
.adviceBtn:hover {
  box-shadow: 0px 0px 3px 2px greenyellow;
}
.activeAdvice {
  background-color: greenyellow;
}

.planInfoBox {
  float: right;
  width: 100%;
}
.emptyTitle {
  width: 100%;
  color: #bbb;
  text-align: center;
}
.adviceInfoBox {
  float: right;
  width: 100%;
}
</style>
